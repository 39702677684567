<template lang="pug">
  <div>
    <LoginModal v-model="showLoginModal" :permanent="true" :redirect="redirect" />
  </div>
</template>

<script>
import LoginModal from '~/components/modals/LoginModal'
import ForgetPassword from '~/components/modals/ForgetPasswordModal'

export default {
  components: {
    LoginModal,
    ForgetPassword
  },
  auth: 'guest',
  data() {
    return {
      showLoginModal: true,
      showForgetModal: false,
      redirect: !!this.$auth.$storage.getCookie('redirect')
    }
  },
  mounted() {
    this.$bus.$on('forget-password', () => {
      this.showForgetModal = true
    })
  }
}
</script>
